import { Flex, Stack } from "@chakra-ui/layout";
import { useState } from "react";
import TableCloseAcademicYear from "./TableCloseAcademicYear";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { MdArrowOutward } from "react-icons/md";

export default function CloseAcademicYear() {
  const [dataTable, setDataTable] = useState([]);

  return (
    <Stack spacing={5}>
      <TableCloseAcademicYear
        setDataTable={setDataTable}
        dataTable={dataTable}
      />
      <Flex justifyContent="end" mt={3}>
        <Link to="/docs/manual-escolas-sietp.pdf" target="_blank">
          <Button variant="secondary" rightIcon={<MdArrowOutward />}>
            Consultar Manual de Apoio
          </Button>
        </Link>
      </Flex>
    </Stack>
  );
}
