import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableGeneralData from "./TableGeneralData";

function TabGeneralData() {
  const data = [
    {
      id: 1,
      name: "Dados globais do ensino técnico-profissional",
    },
  ];
  return (
    <Stack spacing={5}>
      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableGeneralData data={data} />
      </Box>
    </Stack>
  );
}

export default TabGeneralData;
