import { useMemo, useState } from "react";
import Table from "../../../components/Table";
import { Button, Spinner } from "@chakra-ui/react";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import DownloadReport from "./DownloadReport";

function TableSchools({
  data,
  academicYear,
  province,
  county,
  school,
  provinceId,
  countyId,
  schoolId,
  academicYearOptions,
}) {
  let columns = useMemo(
    () => [
      {
        Header: "Indicador",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Tabela base",
        disableSortBy: true,
        Cell: (item) => {
          const [loading, setLoading] = useState(false);
          return (
            <Button
              variant="secondary"
              isDisabled={loading}
              onClick={() => {
                let apiUrl = `/api/Reports/downloadSchoolsReport?Type=${item.row.original.id}`;
                apiUrl += academicYear
                  ? `&AcademicYearId=${academicYear?.value}`
                  : "";
                apiUrl +=
                  province || provinceId
                    ? `&ProvinceId=${province?.value || provinceId}`
                    : "";
                apiUrl +=
                  county || countyId
                    ? `&CountyId=${county?.value || countyId}`
                    : "";
                apiUrl +=
                  school || schoolId
                    ? `&SchoolId=${school?.value || schoolId}`
                    : "";
                downloadExcelFile(
                  apiUrl,
                  `${item.row.original.name}.xlsx`,
                  setLoading
                );
              }}
            >
              {loading ? <Spinner size="sm" /> : "Descarregar"}
            </Button>
          );
        },
      },
      {
        Header: "Quadros padronizados",
        disableSortBy: true,
        Cell: (item) => {
          let apiUrl = `/api/Reports/DownloadSchoolsChartTablesReportRequest?Type=`;
          return item.row.original.id === 2 || item.row.original.id === 3 ? (
            <Button variant="secondary" isDisabled={true}>
              Descarregar
            </Button>
          ) : (
            <DownloadReport
              item={item}
              apiUrl={apiUrl}
              academicYearOptions={academicYearOptions}
            />
          );
        },
      },
    ],
    [academicYear, province, county, school, academicYearOptions]
  );

  return (
    <>{data && <Table columns={columns} data={data} isPaginated={false} />}</>
  );
}

export default TableSchools;
