import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import Select from "react-select";
import { selectStyles } from "../../../utils/styles";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { Toast } from "../../../components/Toast";
import { getProvince } from "../../../services/getProvince";
import { getCounty } from "../../../services/getCounty";
import { getSchool } from "../../../services/getSchool";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

function Filters({
  watch,
  control,
  setValue,
  provinceId,
  countyId,
  schoolId,
  academicYearOptions,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const { addToast } = Toast();

  async function getProvinceOptions() {
    getProvince()
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(id) {
    getCounty({ ParentId: id })
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolOptions(provinceId, countyId) {
    getSchool(provinceId, countyId)
      .then((res) => setSchoolOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getProvinceOptions();
      if (!provinceId) {
        getSchoolOptions();
      }
    },
    // eslint-disable-next-line
    []
  );

  const province = watch("province");

  useEffect(
    () => {
      if (province) {
        getCountyOptions(province?.value);
        getSchoolOptions(province?.value);
      } else if (provinceId && !countyId) {
        setValue("county", undefined);
        setValue("school", undefined);
        getSchoolOptions();
      }
    },
    // eslint-disable-next-line
    [province]
  );

  const county = watch("county");

  useEffect(
    () => {
      if (county) {
        setValue("school", undefined);
        getSchoolOptions(null, county?.value);
      } else if (provinceId && !countyId) {
        setValue("school", undefined);
        getSchoolOptions(provinceId);
      } else if (countyId) {
        getSchoolOptions(null, countyId);
      } else {
        setValue("school", undefined);
        getSchoolOptions(province?.value);
      }
    },
    // eslint-disable-next-line
    [county]
  );

  useEffect(() => {
    if (provinceId) {
      getCountyOptions(provinceId);
      getSchoolOptions(provinceId);
    }
  }, [provinceId]);

  useEffect(() => {
    if (countyId) {
      getSchoolOptions(null, countyId);
    }
  }, [countyId]);

  return (
    <Box px="20px">
      <Grid gridTemplateColumns="1fr auto" gap={4}>
        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
          <GridItem>
            <FormControl>
              <Label title="Ano Lectivo" />
              <Controller
                control={control}
                name="academicYear"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions}
                    onChange={onChange}
                    value={value || ""}
                    styles={selectStyles}
                    isClearable={true}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          {!schoolId && !provinceId && (
            <GridItem>
              <FormControl>
                <Label title="Província" />
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={provinceOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          )}

          {!schoolId && !countyId && (
            <GridItem>
              <FormControl>
                <Label title="Município" />
                <Controller
                  control={control}
                  name="county"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={countyOptions}
                      isDisabled={!province && !provinceId}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          )}

          {!schoolId && (
            <GridItem>
              <FormControl>
                <Label title="Escola" />
                <Controller
                  control={control}
                  name="school"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={schoolOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Filters;
