import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import TabTeacherInformation from "./TabTeacherInformation";
import TabTeacherData from "./TabTeacherData";
import FormButtons from "./FormButtons";
import { useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import TabTeacherSubjects from "./TabTeacherSubjects";

export default function Form({
  isDisabled,
  setIsDisabled,
  id,
  register,
  handleSubmit,
  errors,
  control,
  watch,
  setValue,
  unregister,
  createTeacher,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
  isActive,
  editTeacher,
  cancelEdit,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { addToast } = Toast();
  const [navigationDisabled, setNavigationDisabled] = useState(true);
  const [tabDisabled, setTabDisabled] = useState(true);

  const name = watch("name");
  const birthDate = watch("birthDate");

  useEffect(() => {
    if (!isDisabled && id) {
      setNavigationDisabled(false);
      setTabDisabled(false);
    }
  }, [isDisabled, id]);

  const handleTabChange = (index) => {
    if (!navigationDisabled) {
      if (index !== 2) {
        setActiveTab(index);
      } else {
        if (!tabDisabled) {
          setActiveTab(index);
        }
      }
    }
  };

  const checkBirthDate = (courseEducationalCycleBondedId) => {
    const cycleId = courseEducationalCycleBondedId;
    const inputDate = new Date(birthDate);
    const currentDate = new Date();

    const ageInMilliseconds = currentDate - inputDate;
    const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

    if (
      (cycleId === 5 && ageInYears < 12) ||
      (cycleId === 6 && ageInYears < 15)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (values, event) => {
    const action = event.nativeEvent.submitter.name;

    if (action === "paginate") {
      setNavigationDisabled(false);
      setActiveTab(1);
      return true;
    } else if (action === "step") {
      if (contractualBondRequests.length > 0) {
        setTabDisabled(false);
        setActiveTab(2);
      } else {
        setTabDisabled(true);
        addToast({
          title: "Obrigatório associar vínculo e actividade profissional",
          status: "error",
        });
      }
      return true;
    } else if (action === "create") {
      if (
        contractualBondRequests.some(
          (i) => !i.coursesBound?.length && !i.subjectsBound?.length
        )
      ) {
        addToast({
          title: "Obrigatório associar cursos e disciplinas",
          status: "error",
        });
      } else {
        setIsDisabled(true);
        if (id) {
          editTeacher();
        }
      }
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Heading as="h1">
          {isDisabled
            ? `Resumo | ${name}`
            : id
            ? `Editar ${name}`
            : `Cadastrar Docente`}
        </Heading>
        {isDisabled ? (
          <Stack gap={5}>
            <TabTeacherInformation
              isDisabled={isDisabled}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
            <TabTeacherData
              isDisabled={isDisabled}
              id={id}
              contractualBondRequestsData={contractualBondRequestsData}
            />
            <TabTeacherSubjects
              isDisabled={isDisabled}
              id={id}
              contractualBondRequestsData={contractualBondRequestsData}
            />
          </Stack>
        ) : (
          <>
            <Tabs index={activeTab} onChange={handleTabChange}>
              <TabList>
                <Tab>Informações do Docente</Tab>
                <Tab>Vínculo e Actividade Profissional</Tab>
                <Tab>Cursos e Disciplinas</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} pt="20px">
                  <TabTeacherInformation
                    isDisabled={isDisabled}
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    handleTabChange={handleTabChange}
                    setValue={setValue}
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabTeacherData
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    contractualBondRequests={contractualBondRequests}
                    setContractualBondRequests={setContractualBondRequests}
                    contractualBondRequestsData={contractualBondRequestsData}
                    setContractualBondRequestsData={
                      setContractualBondRequestsData
                    }
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabTeacherSubjects
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    contractualBondRequests={contractualBondRequests}
                    setContractualBondRequests={setContractualBondRequests}
                    contractualBondRequestsData={contractualBondRequestsData}
                    setContractualBondRequestsData={
                      setContractualBondRequestsData
                    }
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
        <FormButtons
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          createTeacher={createTeacher}
          cancelEdit={cancelEdit}
          isActive={isActive}
        />
      </Stack>
    </Box>
  );
}
