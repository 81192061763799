import { useMemo, useEffect, useState } from "react";
import { Button, Center, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getAcademicYear } from "../../../services/getAcademicYear";
import Table from "../../../components/Table";
import { START_CLOSE_ACADEMIC_YEAR } from "../../../utils/endpoints";
import api from "../../../utils/api";
import { Toast } from "../../../components/Toast";
import Alert from "../../../components/Alert";
import { EditIcon } from "@chakra-ui/icons";

export default function TableAcademicYear({ setDataTable, dataTable }) {
  const { addToast } = Toast();
  const [isLoaded, setIsLoded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [idAcademicYear, setIdAcademicYear] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYear();

      setDataTable(response);
      setIsLoded(true);
    };
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Ano Lectivo",
        accessor: "year",
      },
      {
        Header: "Estado",
        accessor: (item) => {
          if (item.open) {
            return item.closeable ? "A encerrar" : "A decorrer";
          }
          return "Encerrado";
        },
        Cell: (item) => {
          return item.row.original.open ? (
            item.row.original.closeable ? (
              <Text>A encerrar</Text>
            ) : (
              <Text>A decorrer</Text>
            )
          ) : (
            <Text>Encerrado</Text>
          );
        },
        sortType: (rowA, rowB) => {
          const estadoA = rowA.original.open
            ? rowA.original.closeable
              ? "A encerrar"
              : "A decorrer"
            : "Encerrado";
          const estadoB = rowB.original.open
            ? rowB.original.closeable
              ? "A encerrar"
              : "A decorrer"
            : "Encerrado";

          return estadoA.localeCompare(estadoB);
        },
      },
      {
        Header: "Notificar",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Button
              variant="secondary"
              isDisabled={item.row.original.closeable}
              onClick={() => {
                setIsOpen(true);
                setIdAcademicYear(item.row.original.id);
              }}
            >
              Notificar fecho do Ano Lectivo
            </Button>
          );
        },
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return item.row.original.open ? (
            <Link to={`/anos-lectivos/${item.data[item.row.id].id}`}>
              <Center>
                <EditIcon />
              </Center>
            </Link>
          ) : (
            <Center _hover={{ cursor: "not-allowed" }}>
              <EditIcon />
            </Center>
          );
        },
      },
    ],
    []
  );

  const handleNotifications = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    let token = localStorage.getItem("@retfoptoken");

    let options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: idAcademicYear }),
    };

    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await fetch(
        `${API_URL}${START_CLOSE_ACADEMIC_YEAR}`,
        options
      );

      if (!response.ok) {
        const error = await response.json();
        let errors = error.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
        setIsOpen(false);
      } else {
        addToast({
          title: "Notificações enviadas com sucesso",
          status: "success",
        });
        setIsLoded(false);
        const response1 = await getAcademicYear();
        setDataTable(response1);
        setIsLoded(true);
        setIsOpen(false);
      }
    } catch (error) {
      console.error("Request error:", error);
    }
  };

  return (
    <>
      <Heading as="h2" size="md" textTransform="uppercase">
        Lista de Anos Lectivos
      </Heading>
      <Table
        setIsLoaded={setIsLoded}
        getFunction={getAcademicYear}
        setData={setDataTable}
        columns={columns}
        data={dataTable}
        isLoaded={isLoaded}
      />
      <Alert
        text="Pretende confirmar o envio de notificações?"
        buttonText="Confirmar"
        handleDelete={handleNotifications}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Notificar fecho do Ano Lectivo"
      />
    </>
  );
}
