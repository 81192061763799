import { useMemo, useState } from "react";
import Table from "../../../components/Table";
import api from "../../../utils/api";
import {
  ACADEMIC_YEAR_URL,
  REOPEN_ACADEMIC_YEAR,
} from "../../../utils/endpoints";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Toast } from "../../../components/Toast";
import Alert from "../../../components/Alert";
import { DIALOG_MESSAGE } from "../../../utils/constants";

function TableSchools({ data, setData, isLoaded }) {
  const { id } = useParams();
  const { addToast } = Toast();
  const [isOpen, setIsOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);

  const reopenAcademicYear = async () => {
    try {
      await api.patch(REOPEN_ACADEMIC_YEAR, {
        schoolId: schoolId,
        yearId: id,
      });
      addToast({ title: "Ano Lectivo reaberto", status: "success" });
      const response = await api.get(`${ACADEMIC_YEAR_URL}/${id}`);
      setData(response.data.academicYearForSchools);
      setIsOpen(false);
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  let columns = useMemo(
    () => [
      {
        Header: "Escola",
        accessor: "school.name",
      },
      {
        Header: "Estado",
        accessor: "closed",
        Cell: (item) => {
          return item.row.original.closed ? "Encerrado" : "Por encerrar";
        },
        sortType: (rowA, rowB) => {
          return rowA.original.closed === rowB.original.closed
            ? 0
            : rowA.original.closed
            ? -1
            : 1;
        },
      },
      {
        Header: "Reabrir",
        disableSortBy: true,
        Cell: (item) => {
          return item.row.original.closed ? (
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpen(true);
                setSchoolId(item.row.original.school.id);
              }}
            >
              Reabrir
            </Button>
          ) : (
            <Button variant="secondary" isDisabled={true}>
              Reabrir
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Table
        data={data}
        columns={columns}
        isLoaded={isLoaded}
        isPaginated={false}
      />
      <Alert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={DIALOG_MESSAGE}
        title="Reabrir Ano Lectivo"
        handleDelete={reopenAcademicYear}
        buttonText="Reabrir"
      />
    </>
  );
}

export default TableSchools;
